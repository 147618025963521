import React from 'react';
import { ModalBody, ModalHeader, ModalTitle } from '../bootstrap/Modal';
import Alert from '../bootstrap/Alert';

type Props = {
	modalTitle: string;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	errorMessage?: string;
};

export const ErrorModal = ({ modalTitle, setIsOpen, errorMessage }: Props) => {
	return (
		<>
			<ModalHeader setIsOpen={setIsOpen}>
				<ModalTitle id={modalTitle}>{modalTitle}</ModalTitle>
			</ModalHeader>

			<ModalBody>
				<Alert color='danger' icon='Error' isLight>
					<span>{errorMessage || 'Desculpe, não foi possível carregar os dados.'}</span>
				</Alert>
			</ModalBody>
		</>
	);
};
