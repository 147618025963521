import { Invoice } from '../../dtos/Invoice';
import { api } from '../api';

export type ReissueExpiredInvoiceRequest = {
	invoiceId: string;
	dueDate: Date;
};

export const reissueExpiredInvoice = async ({
	invoiceId,
	dueDate,
}: ReissueExpiredInvoiceRequest): Promise<Invoice> =>
	(await api.post(`/financial/invoices/${invoiceId}/reissue_expired`, { dueDate })).data;
