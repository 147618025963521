import { useFormik } from 'formik';
import React, { useCallback } from 'react';
import { useToasts } from 'react-toast-notifications';
import Button from '../../../../components/bootstrap/Button';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import { OffCanvasBody } from '../../../../components/bootstrap/OffCanvas';
import Toasts from '../../../../components/bootstrap/Toasts';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import { api } from '../../../../services/api';
import { removeCurrencyMask } from '../../../../utils/removeCurrencyMask';

interface ServicesProps {
	companyId: string;
	triggerCredits: number;
}

interface CompanyServicesProps {
	creditsData: ServicesProps;
	response(credits: ServicesProps): void;
}

export const CompanyCredits: React.FC<CompanyServicesProps> = ({ creditsData, response }) => {
	const { addToast } = useToasts();

	const handleUpdateServices = useCallback(
		async (values: Omit<ServicesProps, 'companyId'>) => {
			const { triggerCredits } = values;

			try {
				await api.patch('restaurant/message-trigger/credits', {
					restaurantId: creditsData.companyId,
					triggerCredits: removeCurrencyMask(triggerCredits),
				});

				addToast(
					<Toasts icon='verifiedUser' title='Sucesso' iconColor='success' isDismiss>
						Empresa editada com sucesso.
					</Toasts>,
					{
						id: 'update_company_success',
						autoDismiss: true,
						appearance: 'warning',
					},
				);

				response({
					companyId: creditsData.companyId,
					triggerCredits,
				});
			} catch (err) {
				console.log(err);
				addToast(
					<Toasts icon='bolt' title='Erro' iconColor='danger' isDismiss>
						Erro ao tentar editar empresa.
					</Toasts>,
					{
						id: 'update_company_error',
						autoDismiss: true,
						appearance: 'error',
					},
				);
			}
		},
		[creditsData, addToast, response],
	);

	const formik = useFormik<Omit<ServicesProps, 'companyId'>>({
		enableReinitialize: true,
		async onSubmit(values): Promise<void | Promise<any>> {
			await handleUpdateServices(values);
		},
		initialValues: {
			triggerCredits: creditsData.triggerCredits ?? 0,
		},
	});

	return (
		<>
			<OffCanvasBody>
				<div className='row g-4'>
					<div className='col-12'>
						<Card isCompact className='mb-0'>
							<CardHeader>
								<CardLabel>
									<CardTitle>Créditos</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<div className='col-12 mt-3'>
									<FormGroup
										id='triggerCredits'
										label='Valor do crédito'
										isFloating>
										<Input
											min={0}
											placeholder='00,00'
											component='NumberFormat'
											onChange={formik.handleChange}
											value={formik.values.triggerCredits}
										/>
									</FormGroup>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</OffCanvasBody>

			<Button color='info' className='m-3' size='lg' onClick={formik.handleSubmit}>
				Save
			</Button>
		</>
	);
};
