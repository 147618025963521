import React from 'react';
import { priceFormat } from '../helpers/helpers';

interface CurrencyProps {
	value?: number;
	className?: string;
}

export const Currency = ({ value, className }: CurrencyProps) => {
	const displayValue = priceFormat(value || 0);

	return <span className={`currency-component ${className || ''}`}>{displayValue}</span>;
};
