import {
	ListInvoicesBySubscriptionRequest,
	ListInvoicesBySubscriptionResponse,
} from '../services/financial/listInvoicesBySubscription';

enum ActionTypes {
	FETCH_SUCCESS = 'FETCH_SUCCESS',
	FETCH_LOADING = 'FETCH_LOADING',
	FETCH_ERROR = 'FETCH_ERROR',
	SET_PAGE = 'SET_PAGE',
	SET_SIZE = 'SET_SIZE',
}

export type InvoicesReducerActions = {
	fetchSuccessAction: (
		totalItems: number,
		totalItemsPaid: number,
		totalItemsCanceled: number,
		items: ListInvoicesBySubscriptionResponse['items'],
	) => void;
	fetchLoadingAction: () => void;
	fetchErrorAction: () => void;
	setPageAction: (page: number) => void;
	setSizeAction: (size: number) => void;
};

export interface InvoicesState {
	totalItems: number;
	totalItemsPaid: number;
	totalItemsCanceled: number;
	items: ListInvoicesBySubscriptionResponse['items'];
	isLoading: boolean;
	isError: boolean;
	params: ListInvoicesBySubscriptionRequest['params'];
}

export function invoicesReducer(state: InvoicesState, action: any): InvoicesState {
	switch (action.type) {
		case ActionTypes.FETCH_SUCCESS:
			return {
				totalItems: action.payload.totalItems,
				totalItemsPaid: action.payload.totalItemsPaid,
				totalItemsCanceled: action.payload.totalItemsCanceled,
				items: action.payload.items,
				isLoading: false,
				isError: false,
				params: state.params,
			};
		case ActionTypes.FETCH_LOADING:
			return {
				...state,
				isLoading: true,
				isError: false,
			};
		case ActionTypes.FETCH_ERROR:
			return {
				totalItems: 0,
				totalItemsPaid: 0,
				totalItemsCanceled: 0,
				items: [],
				isLoading: false,
				isError: true,
				params: {
					page: 1,
					size: 10,
				},
			};
		case ActionTypes.SET_PAGE:
			return {
				...state,
				params: {
					...state.params,
					page: action.payload.page,
				},
			};
		case ActionTypes.SET_SIZE:
			return {
				...state,
				params: {
					...state.params,
					size: action.payload.size,
				},
			};
		default:
			return state;
	}
}

export function invoicesReducerActions(dispatch: React.Dispatch<any>): InvoicesReducerActions {
	function fetchSuccessAction(
		totalItems: number,
		totalItemsPaid: number,
		totalItemsCanceled: number,
		items: ListInvoicesBySubscriptionResponse['items'],
	) {
		dispatch({
			type: ActionTypes.FETCH_SUCCESS,
			payload: {
				totalItems,
				totalItemsPaid,
				totalItemsCanceled,
				items,
			},
		});
	}

	function fetchLoadingAction() {
		dispatch({ type: ActionTypes.FETCH_LOADING });
	}

	function fetchErrorAction() {
		dispatch({ type: ActionTypes.FETCH_ERROR });
	}

	function setPageAction(page: number) {
		dispatch({ type: ActionTypes.SET_PAGE, payload: { page } });
	}

	function setSizeAction(size: number) {
		dispatch({ type: ActionTypes.SET_SIZE, payload: { size } });
	}

	return {
		fetchSuccessAction,
		fetchLoadingAction,
		fetchErrorAction,
		setPageAction,
		setSizeAction,
	};
}
