import * as Yup from 'yup';
import { useFormik } from 'formik';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { api } from '../../../../services/api';
import Toasts from '../../../../components/bootstrap/Toasts';
import { OffCanvasBody } from '../../../../components/bootstrap/OffCanvas';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Checks from '../../../../components/bootstrap/forms/Checks';
import Popovers from '../../../../components/bootstrap/Popovers';
import Icon from '../../../../components/icon/Icon';
import Button from '../../../../components/bootstrap/Button';
import { removeCurrencyMask } from '../../../../utils/removeCurrencyMask';
import { CompanyDTO } from '../../../../dtos/companyDTO';
import Label from '../../../../components/bootstrap/forms/Label';
import FormText from '../../../../components/bootstrap/forms/FormText';
import Alert from '../../../../components/bootstrap/Alert';

type ServicesProps = {
	companyId: string;
	serviceTaxes: {
		active: boolean;
		value: number;
	};
	isLockedAddress: boolean;
	customFields: CompanyDTO['address']['customFields'];
	presentialModule: boolean;
};

type FormInputs = {
	serviceTaxes: {
		active: boolean;
		value: number;
	};
	customAddress: {
		lock: boolean;
		fields: {
			id: string;
			name: string;
			type: 'STRING' | 'NUMBER';
		}[];
	};
	presentialModule: boolean;
};

interface CompanyServicesProps {
	servicesData: ServicesProps;
	fetchData(): Promise<void>;
	close(): void;
}

const formSchema = Yup.object({
	serviceTaxes: Yup.object({
		active: Yup.boolean().required(),
		value: Yup.string().required('Obrigatório'),
	}),
	customAddress: Yup.object({
		lock: Yup.boolean().required(),
		fields: Yup.array()
			.of(
				Yup.object({
					id: Yup.string(),
					name: Yup.string().required('Obrigatório'),
					type: Yup.string().oneOf(['STRING', 'NUMBER']).required(),
				}),
			)
			.test('unique', 'duplicated name', (list = []) => {
				return list.length === new Set(list.map((v) => v.name)).size;
			}),
	}),
});

export const CompanyServices: React.FC<CompanyServicesProps> = ({
	servicesData,
	fetchData,
	close,
}) => {
	const { addToast } = useToasts();

	const formik = useFormik<FormInputs>({
		enableReinitialize: true,
		initialValues: {
			serviceTaxes: {
				active: servicesData.serviceTaxes.active || false,
				value: servicesData.serviceTaxes.value || 0,
			},
			customAddress: {
				lock: servicesData.isLockedAddress || false,
				fields: servicesData.customFields || [],
			},
			presentialModule: servicesData.presentialModule,
		},
		validationSchema: formSchema,
		async onSubmit(values): Promise<void | Promise<any>> {
			const {
				customAddress: { lock, fields },
				serviceTaxes,
				presentialModule,
			} = values;

			try {
				await Promise.all([
					await api.put('/restaurant/service-taxes', {
						restaurantId: servicesData.companyId,
						...serviceTaxes,
						value: removeCurrencyMask(values.serviceTaxes.value),
					}),
					await api.put('/restaurant/address/lock', {
						restaurantId: servicesData.companyId,
						lock: lock,
						customFields: {
							insert: fields.map((field) => ({
								name: field.name,
								type: field.type,
							})),
							remove: servicesData.customFields.map((f) => f.id),
						},
					}),
					await api.put(`/restaurant/metadata/${servicesData.companyId}`, {
						presentialModule,
					}),
					fetchData(),
				]);

				close();

				addToast(
					<Toasts icon='verifiedUser' title='Sucesso' iconColor='success' isDismiss>
						Empresa editada com sucesso.
					</Toasts>,
					{
						id: 'update_company_success',
						autoDismiss: true,
						appearance: 'warning',
					},
				);
			} catch (err) {
				console.log(err);
				addToast(
					<Toasts icon='bolt' title='Erro' iconColor='danger' isDismiss>
						Erro ao tentar editar empresa.
					</Toasts>,
					{
						id: 'update_company_error',
						autoDismiss: true,
						appearance: 'error',
					},
				);
			}
		},
	});

	function addNewInput() {
		formik.setFieldValue('customAddress.fields', [
			...formik.values.customAddress.fields,
			{
				id: Math.random().toString(),
				name: '',
				type: 'STRING',
			},
		]);
	}

	function removeInput(id: string) {
		formik.setFieldValue(
			'customAddress.fields',
			formik.values.customAddress.fields.filter((field) => field.id !== id),
		);
	}

	function getErrorByIndex(index: number): string {
		const { customAddress } = formik.errors;

		if (customAddress?.fields?.length && customAddress.fields[index]) {
			const errorMessage: any = customAddress.fields[index];

			if (errorMessage && errorMessage.name) {
				return errorMessage.name as string;
			}
		}

		return '';
	}

	return (
		<>
			<OffCanvasBody>
				<div className='row g-4'>
					<div className='col-12'>
						<Card isCompact className='mb-0'>
							<CardHeader>
								<CardLabel>
									<CardTitle>Taxa de serviço variável</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<FormGroup>
									<Checks
										id='serviceTaxes.active'
										type='switch'
										label={
											<>
												Sobre essa taxa
												<Popovers
													trigger='hover'
													desc='Check this checkbox if you want your customer to receive an email about the scheduled appointment'>
													<Icon
														icon='Help'
														size='lg'
														className='ms-1 cursor-help'
													/>
												</Popovers>
											</>
										}
										onChange={formik.handleChange}
										checked={formik.values.serviceTaxes.active}
									/>
								</FormGroup>

								{formik.values.serviceTaxes.active && (
									<div className='col-12 mt-3'>
										<FormGroup label='Valor da taxa' isFloating>
											<Input
												min={0}
												id='serviceTaxes.value'
												placeholder='Valor da taxa'
												component='NumberFormat'
												onChange={formik.handleChange}
												value={formik.values.serviceTaxes.value}
											/>

											<FormText className='text-danger'>
												{formik.errors.serviceTaxes?.value || ''}
											</FormText>
										</FormGroup>
									</div>
								)}
							</CardBody>
						</Card>

						<Card isCompact className='mt-3'>
							<CardHeader>
								<CardLabel>
									<CardTitle>Endereço personalizável</CardTitle>
								</CardLabel>

								<Checks
									id='customAddress.lock'
									type='switch'
									onChange={formik.handleChange}
									checked={formik.values.customAddress.lock}
								/>
							</CardHeader>

							<CardBody>
								{formik.errors.customAddress?.fields === 'duplicated name' && (
									<Alert color='danger'>
										Não pode ter dois campos com o mesmo valor
									</Alert>
								)}

								{formik.values.customAddress.lock && (
									<div className='mt-0 row g-4'>
										{formik.values.customAddress.fields.map((field, index) => (
											<FormGroup key={index}>
												<div className='d-flex gap-3'>
													<Label>{`Editável ${index}`}</Label>

													<Checks
														className='ml-auto'
														type='checkbox'
														label='Texto'
														checked={
															formik.values.customAddress.fields[
																index
															].type === 'STRING'
														}
														onChange={() => {
															formik.setFieldValue(
																`customAddress.fields.${index}.type`,
																'STRING',
															);
														}}
													/>

													<Checks
														type='checkbox'
														label='Número'
														checked={
															formik.values.customAddress.fields[
																index
															].type === 'NUMBER'
														}
														onChange={() => {
															formik.setFieldValue(
																`customAddress.fields.${index}.type`,
																'NUMBER',
															);
														}}
													/>
												</div>

												<div className='d-flex gap-3'>
													<Input
														id={`customAddress.fields.${index}.name`}
														value={
															formik.values.customAddress.fields[
																index
															].name
														}
														onChange={formik.handleChange}
													/>

													<Button
														color='info'
														icon='delete'
														onClick={() => removeInput(field.id)}
													/>
												</div>

												<FormText className='text-danger'>
													{getErrorByIndex(index)}
												</FormText>
											</FormGroup>
										))}

										<Button
											color='info'
											icon='add'
											className='mt-4'
											onClick={addNewInput}
										/>
									</div>
								)}
							</CardBody>
						</Card>

						<Card isCompact className='mt-3'>
							<CardHeader>
								<CardLabel>
									<CardTitle>Módulo presencial</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<FormGroup>
									<Checks
										id='presentialModule'
										type='switch'
										label={
											<>
												Sobre o módulo
												<Popovers
													trigger='hover'
													desc='Ativa as funcionalidades para o atendimento presencial da loja'>
													<Icon
														icon='Help'
														size='lg'
														className='ms-1 cursor-help'
													/>
												</Popovers>
											</>
										}
										onChange={formik.handleChange}
										checked={formik.values.presentialModule}
									/>
								</FormGroup>
							</CardBody>
						</Card>
					</div>
				</div>
			</OffCanvasBody>

			<Button
				color='info'
				className='m-3'
				size='lg'
				onClick={formik.handleSubmit}
				isDisable={!formik.dirty || !formik.isValid}>
				Save
			</Button>
		</>
	);
};
