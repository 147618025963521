import React from 'react';
import * as y from 'yup';
import {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../../components/bootstrap/Modal';
import Button from '../../../../components/bootstrap/Button';
import { useFormik } from 'formik';
import Spinner from '../../../../components/bootstrap/Spinner';
import { financialService } from '../../../../services/financial';
import Popovers from '../../../../components/bootstrap/Popovers';
import { Calendar as DatePicker } from 'react-date-range';
import dayjs from 'dayjs';
import { ptBR } from 'date-fns/locale';
import Label from '../../../../components/bootstrap/forms/Label';
import { FullSubscription } from '../../../../dtos/Subscription';
import { financialNotifications } from './utils';
import { Invoice } from '../../../../dtos/Invoice';
import { isDue } from '../../../../utils/isDue';

interface Props {
	invoice: Invoice;
	subscription: FullSubscription;
	setModalStatus: React.Dispatch<React.SetStateAction<boolean>>;
	fetchData: () => Promise<void>;
}

const schema = y.object({
	dueDate: y.date().required(),
});

type SchemaType = y.InferType<typeof schema>;

export const ReissueExpiredInvoiceModal = ({
	setModalStatus,
	fetchData,
	subscription,
	invoice,
}: Props) => {
	const formik = useFormik<SchemaType>({
		initialValues: {
			dueDate: new Date(invoice.dueDate),
		},
		validationSchema: schema,
		onSubmit: async ({ dueDate }) => {
			if (!!subscription.canceledAt) return financialNotifications.canceledSubscription();

			if (!!invoice.canceledAt) return financialNotifications.canceledInvoice();

			if (!!invoice.paidAt) return financialNotifications.paidInvoice();

			if (!isDue(invoice.dueDate)) return financialNotifications.notDueInvoice();

			if (!!isDue(dueDate)) return financialNotifications.pastDueDateInvoice();

			try {
				await financialService.invoices.reissueExpired({
					invoiceId: invoice.id,
					dueDate,
				});

				financialNotifications.reissuedInvoice();
				setModalStatus(false);
				fetchData();
			} catch (error) {
				financialNotifications.genericError();
			}
		},
	});

	return (
		<>
			<ModalHeader setIsOpen={setModalStatus}>
				<ModalTitle id='Reemitir fatura expirada'>Reemitir fatura expirada</ModalTitle>
			</ModalHeader>

			<ModalBody className='d-flex row g-4'>
				<Label>Selecione a nova data de vencimento</Label>

				<div>
					<Popovers
						desc={
							<DatePicker
								onChange={(item) => formik.setFieldValue('dueDate', item)}
								date={formik.values.dueDate}
								locale={ptBR}
								minDate={new Date()}
							/>
						}
						placement='right'
						className='mw-100'
						trigger='click'>
						<Button color='info' isLight>
							{dayjs(formik.values.dueDate).format('DD/MM/YYYY')}
						</Button>
					</Popovers>
				</div>
			</ModalBody>

			<ModalFooter>
				<Button icon='Close' color='danger' isLink onClick={() => setModalStatus(false)}>
					Cancelar
				</Button>
				<Button
					icon='DoneOutline'
					color='success'
					isLight
					isDisable={!formik.isValid || formik.isSubmitting}
					onClick={formik.handleSubmit}>
					{formik.isSubmitting && <Spinner isSmall inButton />}
					{formik.isSubmitting ? 'Reemitindo Fatura' : 'Reemitir Fatura'}
				</Button>
			</ModalFooter>
		</>
	);
};
