import { Plan } from '../../dtos/Plan';
import { api } from '../api';

export type ListPlansRequest = {
	params: {
		page: number;
		size: number;
	};
};

export type ListPlansResponse = {
	totalItems: number;
	items: Plan[];
};

export const listPlans = async ({ params }: ListPlansRequest): Promise<ListPlansResponse> =>
	(await api.get(`/financial/plans`, { params })).data;
