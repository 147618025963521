import React from 'react';

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

interface RelativeTimeProps {
	datetime: string;
	format?: string;
	className?: string;
}

export const RelativeTime = ({
	datetime,
	format = 'DD/MM/YYYY, HH:mm',
	className,
}: RelativeTimeProps) => {
	const isValidDate = dayjs(datetime).isValid();

	if (!isValidDate) return <span />;

	return (
		<time dateTime={datetime} className={`relative-time-component ${className || ''}`}>
			{dayjs(datetime).locale('pt-br').format(format)}
		</time>
	);
};
