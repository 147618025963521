import { differenceInMonths, differenceInWeeks, formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export function getMonths(start: Date, end?: Date | null) {
	const endDate = end ? new Date(end) : new Date();

	const endDateWithoutTime = new Date(endDate.setHours(0, 0, 0, 0));
	const startDateWithoutTime = new Date(new Date(start).setHours(0, 0, 0, 0));

	return differenceInMonths(endDateWithoutTime, startDateWithoutTime);
}

export function getWeeks(start: Date, end?: Date | null) {
	const endDate = end ? new Date(end) : new Date();

	const endDateWithoutTime = new Date(endDate.setHours(0, 0, 0, 0));
	const startDateWithoutTime = new Date(new Date(start).setHours(0, 0, 0, 0));

	return differenceInWeeks(endDateWithoutTime, startDateWithoutTime);
}

export function getDistance(start: Date, end?: Date | null) {
	const endDate = end ? new Date(end) : new Date();

	const endDateWithoutTime = new Date(endDate.setHours(0, 0, 0, 0));
	const startDateWithoutTime = new Date(new Date(start).setHours(0, 0, 0, 0));

	return formatDistance(endDateWithoutTime, startDateWithoutTime, { locale: ptBR });
}
