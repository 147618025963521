import React, { useCallback, useEffect, useState } from 'react';

import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../layout/SubHeader/SubHeader';
import Page from '../../../layout/Page/Page';
import useDarkMode from '../../../hooks/useDarkMode';
import moment from 'moment';
import { companiesPageMenu } from '../../../menu';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import classNames from 'classnames';
import OffCanvas, { OffCanvasHeader } from '../../../components/bootstrap/OffCanvas';
import PaginationButtons from '../../../components/PaginationButtons';
import { CompanyDTO } from '../../../dtos/companyDTO';
import { api } from '../../../services/api';
import { GetCompaniesDTO } from '../../../dtos/getCompaniesDTO';
import { registerNumberFormat } from '../../../helpers/helpers';
import { CompanyData } from './components/CompanyData';
import { CompanyServices } from './components/CompanyServices';
import { CompanyCredits } from './components/CompanyCredits';
import { useClipboard } from 'use-clipboard-copy';

export type MenuOptionType = 'DATA' | 'SERVICES' | 'CREDITS';

const CompaniesPage = () => {
	const { darkModeStatus } = useDarkMode();

	const [upCompanyEditOffcanvas, setUpCompanyEditOffcanvas] = useState<CompanyDTO | undefined>();
	const [companies, setCompanies] = useState<CompanyDTO[]>([]);
	const [menuOption, setMenuOption] = useState<MenuOptionType>('DATA');

	const [copiedStoreId, setCopiedStoreId] = useState('');
	const { copied, copy } = useClipboard({
		copiedTimeout: 1000,
	});

	const [currentPage, setCurrentPage] = useState(1);
	const [pages, setPages] = useState(1);
	const perPage = 10;

	const totalItems = Math.ceil(perPage * pages);

	const fetchData = useCallback(async () => {
		try {
			const { data } = await api.get<GetCompaniesDTO>('/restaurant/list', {
				params: {
					page: currentPage - 1,
				},
			});

			setCompanies(data.restaurants);

			setPages(data.pages + 1);
		} catch (err) {
			console.log(err);
		}
	}, [currentPage]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const handleUpdateCompany = (companyUpdate: CompanyDTO) => {
		setCompanies((state) =>
			state.map((company) => {
				if (company.id === companyUpdate.id) {
					return companyUpdate;
				}

				return company;
			}),
		);
	};

	return (
		<PageWrapper title={companiesPageMenu.companies.text}>
			<SubHeader>
				<SubHeaderLeft>Sub Header Left</SubHeaderLeft>
				<SubHeaderRight>Sub Header Right</SubHeaderRight>
			</SubHeader>
			<Page container='fluid'>
				<div className='row'>
					<div className='col-12'>
						<Card>
							<CardHeader borderSize={1}>
								<CardLabel icon='Storefront' iconColor='info'>
									<CardTitle>Todas as empresas</CardTitle>
								</CardLabel>
								<CardActions>
									<div />
								</CardActions>
							</CardHeader>
							<CardBody className='table-responsive'>
								<table className='table table-modern'>
									<thead>
										<tr>
											<th>Empresa</th>
											<th>CPF/CNPJ</th>
											<th>Criada em</th>
											<td />
										</tr>
									</thead>
									<tbody>
										{companies.map((item: CompanyDTO) => (
											<tr key={item.id}>
												<td>
													<div className='d-flex'>
														<Button
															title='Copiar ID'
															icon={
																copiedStoreId === item.id && copied
																	? 'Check'
																	: 'ContentCopy'
															}
															onClick={() => {
																copy(item.id);
																setCopiedStoreId(item.id);
															}}
														/>
														<div className='flex-shrink-0'>
															<img
																srcSet={item.picture}
																src={item.picture}
																alt={item.name}
																width='36'
																height='36'
															/>
														</div>
														<div className='flex-grow-1 ms-3 d-flex align-items-center'>
															{item.name}
														</div>
													</div>
												</td>
												<td>
													<div>
														<div>
															{registerNumberFormat(
																item.registerNumber,
															)}
														</div>
													</div>
												</td>
												<td>
													<span className='text-nowrap'>
														{moment(item.createdAt).format(
															'DD/MM/YYYY',
														)}
													</span>
												</td>
												<td>
													<Button
														isOutline={!darkModeStatus}
														color='dark'
														isLight={darkModeStatus}
														className={classNames('text-nowrap', {
															'border-light': !darkModeStatus,
														})}
														icon='Edit'
														onClick={() =>
															setUpCompanyEditOffcanvas(item)
														}
													/>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</CardBody>
							<CardFooter>
								<CardFooterRight>
									<PaginationButtons
										data={[...new Array(totalItems)]}
										label='Empresas'
										setCurrentPage={setCurrentPage}
										currentPage={currentPage}
										perPage={perPage}
									/>
								</CardFooterRight>
							</CardFooter>
						</Card>

						<OffCanvas
							setOpen={() => setUpCompanyEditOffcanvas(undefined)}
							isOpen={!!upCompanyEditOffcanvas}
							titleId='upcomingEdit'
							isBackdrop>
							<OffCanvasHeader setOpen={() => setUpCompanyEditOffcanvas(undefined)}>
								<ButtonGroup>
									<Button
										isActive={menuOption === 'DATA'}
										isOutline={menuOption !== 'DATA'}
										onClick={() => setMenuOption('DATA')}
										color='primary'>
										Dados
									</Button>
									<Button
										isActive={menuOption === 'SERVICES'}
										isOutline={menuOption !== 'SERVICES'}
										onClick={() => setMenuOption('SERVICES')}
										color='primary'>
										Serviços
									</Button>
									<Button
										isActive={menuOption === 'CREDITS'}
										isOutline={menuOption !== 'CREDITS'}
										onClick={() => setMenuOption('CREDITS')}
										color='primary'>
										Créditos
									</Button>
								</ButtonGroup>
							</OffCanvasHeader>

							{menuOption === 'DATA' && upCompanyEditOffcanvas && (
								<CompanyData
									companyData={upCompanyEditOffcanvas}
									response={handleUpdateCompany}
								/>
							)}

							{menuOption === 'SERVICES' && upCompanyEditOffcanvas && (
								<CompanyServices
									servicesData={{
										companyId: upCompanyEditOffcanvas.id,
										serviceTaxes: {
											value: upCompanyEditOffcanvas.config.serviceTaxes,
											active: upCompanyEditOffcanvas.config
												.serviceTaxesActive,
										},
										isLockedAddress: upCompanyEditOffcanvas.isLockedAddress,
										customFields: upCompanyEditOffcanvas.address.customFields,
										presentialModule:
											upCompanyEditOffcanvas.config.presentialModule,
									}}
									fetchData={fetchData}
									close={() => setUpCompanyEditOffcanvas(undefined)}
								/>
							)}

							{menuOption === 'CREDITS' && upCompanyEditOffcanvas && (
								<CompanyCredits
									creditsData={{
										companyId: upCompanyEditOffcanvas.id,
										triggerCredits:
											upCompanyEditOffcanvas.config.triggerCredits,
									}}
									response={(creditsUpdate) => {
										handleUpdateCompany({
											...upCompanyEditOffcanvas,
											config: {
												...upCompanyEditOffcanvas.config,
												triggerCredits: creditsUpdate.triggerCredits,
											},
										});
									}}
								/>
							)}
						</OffCanvas>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default CompaniesPage;
