import { Invoice } from '../../dtos/Invoice';
import { api } from '../api';

export type ListInvoicesBySubscriptionRequest = {
	subscriptionId: string;
	params: {
		page: number;
		size: number;
	};
};

export type ListInvoicesBySubscriptionResponse = {
	totalItems: number;
	totalItemsPaid: number;
	totalItemsCanceled: number;
	items: Array<Invoice>;
};

export const listInvoicesBySubscription = async ({
	subscriptionId,
	params,
}: ListInvoicesBySubscriptionRequest): Promise<ListInvoicesBySubscriptionResponse> =>
	(await api.get(`/financial/invoices/${subscriptionId}/subscription`, { params })).data;
