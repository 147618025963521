import React from 'react';
import * as y from 'yup';
import {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../../components/bootstrap/Modal';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Button from '../../../../components/bootstrap/Button';
import Input from '../../../../components/bootstrap/forms/Input';
import { useFormik } from 'formik';
import Spinner from '../../../../components/bootstrap/Spinner';
import Select from '../../../../components/bootstrap/forms/Select';
import Option from '../../../../components/bootstrap/Option';
import { currencyToNumber, numberToCurrency } from '../../../../utils/convert-utils';
import { financialService } from '../../../../services/financial';
import showNotification from '../../../../components/extras/showNotification';
import { Plan } from '../../../../dtos/Plan';

interface Props {
	setModalStatus: React.Dispatch<React.SetStateAction<boolean>>;
	fetchData: () => Promise<void>;
}

const schema = y.object({
	name: y.string().trim().required(),
	interval: y.number().integer().min(1).required(),
	intervalType: y.string().oneOf(['WEEKS', 'MONTHS']).required(),
	value: y.number().min(0).required(),
});

type SchemaType = y.InferType<typeof schema> & {
	intervalType: Plan['intervalType'];
};

export const NewPlanModal = ({ setModalStatus, fetchData }: Props) => {
	const formik = useFormik<SchemaType>({
		initialValues: {
			name: '',
			interval: 1,
			intervalType: 'MONTHS',
			value: 0,
		},
		validationSchema: schema,
		onSubmit: async (data) => {
			try {
				await financialService.plans.create(data);
				showNotification('Plano criado!', 'O Plano foi criado com sucesso!', 'success');
				setModalStatus(false);
				fetchData();
			} catch (error: any) {
				if (error.response.data.message.includes('Plan name already exists')) {
					formik.setFieldError('name', 'Já existe um plano com esse nome');
					return;
				}
				showNotification('Falha!', 'Ocorreu algum erro!', 'danger');
			}
		},
	});

	return (
		<>
			<ModalHeader setIsOpen={setModalStatus}>
				<ModalTitle id='new-plan'>Criar Plano</ModalTitle>
			</ModalHeader>

			<ModalBody className='d-flex row g-4'>
				<FormGroup id='name' label='Nome'>
					<Input
						size='lg'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.name}
						isValid={formik.isValid}
						isTouched={formik.touched?.name}
						invalidFeedback={formik.errors?.name}
						validFeedback='Parece bom!'
					/>
				</FormGroup>

				<FormGroup id='interval' label='Intervalo'>
					<Input
						type='number'
						size='lg'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.interval}
						isValid={formik.isValid}
						isTouched={formik.touched?.interval}
						invalidFeedback={formik.errors?.interval}
						validFeedback='Parece bom!'
					/>
				</FormGroup>

				<FormGroup id='intervalType' label='Tipo do Intervalo'>
					<Select
						ariaLabel='Tipo'
						size='lg'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.intervalType}
						isValid={formik.isValid}
						isTouched={formik.touched?.intervalType}
						invalidFeedback={formik.errors?.intervalType}
						validFeedback='Parece bom!'>
						<Option value='WEEKS'>Semanas</Option>
						<Option value='MONTHS'>Meses</Option>
					</Select>
				</FormGroup>

				<FormGroup id='value' label='Valor'>
					<Input
						size='lg'
						value={numberToCurrency(formik.values.value)}
						onChange={(e: any) => {
							const value = currencyToNumber(e.target.value);
							formik.setFieldValue('value', Number(value));
						}}
						onBlur={formik.handleBlur}
						isValid={formik.isValid}
						isTouched={formik.touched?.value}
						invalidFeedback={formik.errors?.value}
						validFeedback='Parece bom!'
					/>
				</FormGroup>
			</ModalBody>

			<ModalFooter>
				<Button icon='Close' color='danger' isLink onClick={() => setModalStatus(false)}>
					Cancelar
				</Button>
				<Button
					icon='DoneOutline'
					color='success'
					isLight
					isDisable={!formik.isValid || formik.isSubmitting}
					onClick={formik.handleSubmit}>
					{formik.isSubmitting && <Spinner isSmall inButton />}
					{formik.isSubmitting ? 'Criando plano' : 'Criar Plano'}
				</Button>
			</ModalFooter>
		</>
	);
};
