import React, { useCallback, useEffect, useReducer, useState } from 'react';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../../components/bootstrap/Modal';
import { invoicesReducer, invoicesReducerActions } from '../../../../reducers/invoicesReducer';
import { financialService } from '../../../../services/financial';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import PaginationButtons from '../../../../components/PaginationButtons';
import { RelativeTime } from '../../../../components/RelativeTime';
import Button from '../../../../components/bootstrap/Button';
import Badge from '../../../../components/bootstrap/Badge';
import { CardData } from './CardData';
import { LoadingModal } from '../../../../components/pages/LoadingModal';
import { ErrorModal } from '../../../../components/pages/ErrorModal';
import { NewInvoiceModal } from './NewInvoiceModal';
import { FullSubscription } from '../../../../dtos/Subscription';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../../components/bootstrap/Dropdown';
import Swal from 'sweetalert2';
import { defaultAlertOptions } from '../../../../utils/constants';
import { Invoice } from '../../../../dtos/Invoice';
import { financialNotifications, intervalTypeParsed, invoiceUtils } from './utils';
import { isDue } from '../../../../utils/isDue';
import { ReissueExpiredInvoiceModal } from './ReissueExpiredInvoiceModal';
import { priceFormat } from '../../../../helpers/helpers';
import Alert from '../../../../components/bootstrap/Alert';

import customStyles from './customStyles.module.scss';

interface Props {
	subscription: FullSubscription;
	setModalStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InvoicesModal = ({ setModalStatus, subscription }: Props) => {
	const [modal, setModal] = useState(false);

	const [reissueModal, setReissueModal] = useState(false);
	const [selectedInvoice, setSelectedInvoice] = useState({} as Invoice);

	const [state, dispatch] = useReducer(invoicesReducer, {
		totalItems: 0,
		totalItemsPaid: 0,
		totalItemsCanceled: 0,
		items: [],
		isLoading: false,
		isError: false,
		params: {
			page: 1,
			size: 5,
		},
	});

	const { isError, isLoading, params } = state;

	const actions = invoicesReducerActions(dispatch);

	const fetchData = useCallback(async () => {
		actions.fetchLoadingAction();

		try {
			const { totalItems, totalItemsPaid, totalItemsCanceled, items } =
				await financialService.invoices.listBySubscription({
					subscriptionId: subscription.id,
					params: {
						page: params.page - 1,
						size: params.size,
					},
				});

			actions.fetchSuccessAction(totalItems, totalItemsPaid, totalItemsCanceled, items);
		} catch {
			actions.fetchErrorAction();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params]);

	useEffect(() => {
		if (!isError) {
			fetchData();
		}
	}, [fetchData, isError]);

	if (isLoading) return <LoadingModal modalTitle='Faturas' setIsOpen={setModalStatus} />;

	if (isError) return <ErrorModal modalTitle='Faturas' setIsOpen={setModalStatus} />;

	const handleSendByEmail = async (invoice: Invoice) => {
		if (!!subscription.canceledAt) return financialNotifications.canceledSubscription();

		if (subscription.subscriberType !== 'STORE')
			return financialNotifications.sendInvoiceOnlyStore();

		if (!!invoice.canceledAt) return financialNotifications.canceledInvoice();

		if (!!invoice.paidAt) return financialNotifications.paidInvoice();

		if (!!isDue(invoice.dueDate)) return financialNotifications.dueInvoice();

		Swal.fire({
			title: `Enviar fatura via E-mail`,
			text: 'Tem certeza que deseja enviar essa fatura via E-mail?',
			...defaultAlertOptions,
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					await financialService.invoices.sendByEmail(invoice.id);
					financialNotifications.sendedInvoice();
				} catch {
					financialNotifications.genericError();
				}
			}
		});
	};

	const handleSendByWhatsApp = async (invoice: Invoice) => {
		if (!!subscription.canceledAt) return financialNotifications.canceledSubscription();

		if (subscription.subscriberType !== 'STORE')
			return financialNotifications.sendInvoiceOnlyStore();

		if (!!invoice.canceledAt) return financialNotifications.canceledInvoice();

		if (!!invoice.paidAt) return financialNotifications.paidInvoice();

		if (!!isDue(invoice.dueDate)) return financialNotifications.dueInvoice();

		Swal.fire({
			title: `Enviar fatura via WhatsApp`,
			text: 'Tem certeza que deseja enviar essa fatura via WhatsApp?',
			...defaultAlertOptions,
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					await financialService.invoices.sendByWhatsapp(invoice.id);
					financialNotifications.sendedInvoice();
				} catch {
					financialNotifications.genericError();
				}
			}
		});
	};

	const handleCancel = async (invoice: Invoice) => {
		if (!!subscription.canceledAt) return financialNotifications.canceledSubscription();

		if (!!invoice.canceledAt) return financialNotifications.canceledInvoice();

		if (!!invoice.paidAt) return financialNotifications.paidInvoice();

		if (!!isDue(invoice.dueDate)) return financialNotifications.dueInvoice();

		Swal.fire({
			title: `Cancelar fatura`,
			text: 'Tem certeza que deseja cancelar essa fatura?',
			...defaultAlertOptions,
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					await financialService.invoices.cancel(invoice.id);
					financialNotifications.cancelInvoice();
					fetchData();
				} catch {
					financialNotifications.genericError();
				}
			}
		});
	};

	const handlePay = async (invoice: Invoice) => {
		if (!!subscription.canceledAt) return financialNotifications.canceledSubscription();

		if (!!invoice.canceledAt) return financialNotifications.canceledInvoice();

		if (!!invoice.paidAt) return financialNotifications.paidInvoice();

		if (!!isDue(invoice.dueDate)) return financialNotifications.dueInvoice();

		Swal.fire({
			title: `Marcar fatura como paga`,
			text: 'Tem certeza que deseja marcar essa fatura como paga?',
			...defaultAlertOptions,
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					await financialService.invoices.payExternally(invoice.id);
					financialNotifications.payInvoice();
					fetchData();
				} catch {
					financialNotifications.genericError();
				}
			}
		});
	};

	const myPlanUse = invoiceUtils.getUseOfPlan(subscription);

	const myPlanDistanceUse = invoiceUtils.getDistanceUseOfPlan(subscription);

	const myPlan = `(${priceFormat(subscription.plan.value)} a cada ${subscription.plan.interval} ${
		intervalTypeParsed[subscription.plan.intervalType]
	})`;

	const totalItemsPending = state.totalItems - state.totalItemsPaid - state.totalItemsCanceled;

	const isOkPlan = !!(state.totalItemsPaid >= myPlanUse);

	const invoicesToPay = myPlanUse - state.totalItemsPaid;

	return (
		<>
			<ModalHeader setIsOpen={setModalStatus}>
				<ModalTitle id='invoices'>Faturas</ModalTitle>
			</ModalHeader>

			<ModalBody className='d-flex row g-4'>
				<div className={`${customStyles.responsiveGrid}`}>
					<CardData
						label='Uso do plano'
						value={[`${myPlanDistanceUse} (${myPlanUse})`, myPlan]}
					/>

					<CardData
						label='Faturas pagas'
						value={[state.totalItemsPaid]}
						color='success'
					/>

					<CardData
						label='Faturas pendentes'
						value={[totalItemsPending]}
						color='warning'
					/>

					<CardData
						label='Faturas canceladas'
						value={[state.totalItemsCanceled]}
						color='dark'
					/>
				</div>

				<div>
					{isOkPlan ? (
						<Alert color='success' icon='Check' isLight>
							<span>Tudo ok!</span>
						</Alert>
					) : (
						<Alert color='danger' icon='Error' isLight>
							<span>Necessita pagar {invoicesToPay} faturas para ficar em dias!</span>
						</Alert>
					)}
				</div>

				<Card>
					<CardHeader borderSize={1}>
						<CardLabel>
							<CardTitle>Faturas</CardTitle>
							<CardSubTitle>
								<Badge className='fs-6' isLight color='info'>
									{state.totalItems} Faturas
								</Badge>
							</CardSubTitle>
						</CardLabel>

						<CardActions>
							<Button
								color='primary'
								icon='Add'
								onClick={() => {
									setModal(true);
								}}>
								Criar Fatura
							</Button>
						</CardActions>
					</CardHeader>

					<CardBody>
						<table className='table table-modern'>
							<thead>
								<tr>
									<th>Data de Criação</th>
									<th>Situação</th>
									<th>Vencimento</th>
									<th> </th>
									<th> </th>
									<th> </th>
								</tr>
							</thead>

							<tbody>
								{state.items.map((item) => (
									<tr key={item.id}>
										<td>
											<RelativeTime datetime={item.createdAt.toString()} />
										</td>

										<td>
											{!!item.paidAt ? (
												<Badge className='fs-6' color='success'>
													Paga{' '}
													<RelativeTime
														datetime={item.paidAt.toString()}
													/>
												</Badge>
											) : (
												<Badge className='fs-6' color='warning'>
													Pendente
												</Badge>
											)}
										</td>

										<td>
											<p className={isDue(item.dueDate) ? 'text-danger' : ''}>
												<RelativeTime
													datetime={item.dueDate.toString()}
													format='DD/MM/YYYY'
												/>
											</p>
										</td>

										<td>
											{!!item.canceledAt && (
												<Badge className='fs-6' color='dark'>
													Cancelada{' '}
													<RelativeTime
														datetime={item.canceledAt.toString()}
													/>
												</Badge>
											)}
										</td>

										<td>
											<Button
												size='sm'
												color='primary'
												isLight
												tag='a'
												to={item.url}
												target='_blank'>
												Ver
											</Button>
										</td>

										<td>
											<Dropdown>
												<DropdownToggle hasIcon={false}>
													<Button icon='MoreHoriz' shadow='default' />
												</DropdownToggle>

												<DropdownMenu isAlignmentEnd>
													<DropdownItem>
														<Button
															icon='Mail'
															onClick={() => handleSendByEmail(item)}>
															Enviar via E-mail
														</Button>
													</DropdownItem>

													<DropdownItem>
														<Button
															icon='Phone'
															onClick={() =>
																handleSendByWhatsApp(item)
															}>
															Enviar via WhatsApp
														</Button>
													</DropdownItem>

													<DropdownItem>
														<Button
															icon='Cancel'
															onClick={() => handleCancel(item)}>
															Cancelar
														</Button>
													</DropdownItem>

													<DropdownItem>
														<Button
															icon='Paid'
															onClick={() => handlePay(item)}>
															Marcar como paga
														</Button>
													</DropdownItem>

													<DropdownItem>
														<Button
															icon='FileCopy'
															onClick={() => {
																setSelectedInvoice(item);
																setReissueModal(true);
															}}>
															Reemitir fatura expirada
														</Button>
													</DropdownItem>
												</DropdownMenu>
											</Dropdown>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</CardBody>

					<PaginationButtons
						data={[...new Array(state.totalItems)]}
						label='Faturas'
						currentPage={params.page}
						perPage={params.size}
						setCurrentPage={actions.setPageAction}
						setPerPage={actions.setSizeAction}
					/>
				</Card>

				<Modal
					isOpen={modal}
					setIsOpen={setModal}
					size='lg'
					titleId='Criar Fatura'
					isCentered
					isStaticBackdrop>
					<NewInvoiceModal
						subscription={subscription}
						setModalStatus={setModal}
						fetchData={fetchData}
					/>
				</Modal>

				<Modal
					isOpen={reissueModal}
					setIsOpen={setReissueModal}
					size='lg'
					titleId='Reemitir fatura expirada'
					isCentered
					isStaticBackdrop>
					<ReissueExpiredInvoiceModal
						invoice={selectedInvoice}
						subscription={subscription}
						setModalStatus={setReissueModal}
						fetchData={fetchData}
					/>
				</Modal>
			</ModalBody>
		</>
	);
};
