import {
	ListSubscriptionsRequest,
	ListSubscriptionsResponse,
} from '../services/financial/listSubscriptions';

enum ActionTypes {
	FETCH_SUCCESS = 'FETCH_SUCCESS',
	FETCH_LOADING = 'FETCH_LOADING',
	FETCH_ERROR = 'FETCH_ERROR',
	SET_PAGE = 'SET_PAGE',
	SET_SIZE = 'SET_SIZE',
}

export type SubscriptionsReducerActions = {
	fetchSuccessAction: (totalItems: number, items: ListSubscriptionsResponse['items']) => void;
	fetchLoadingAction: () => void;
	fetchErrorAction: () => void;
	setPageAction: (page: number) => void;
	setSizeAction: (size: number) => void;
};

export interface SubscriptionsState {
	totalItems: number;
	items: ListSubscriptionsResponse['items'];
	isLoading: boolean;
	isError: boolean;
	params: ListSubscriptionsRequest['params'];
}

export function subscriptionsReducer(state: SubscriptionsState, action: any): SubscriptionsState {
	switch (action.type) {
		case ActionTypes.FETCH_SUCCESS:
			return {
				totalItems: action.payload.totalItems,
				items: action.payload.items,
				isLoading: false,
				isError: false,
				params: state.params,
			};
		case ActionTypes.FETCH_LOADING:
			return {
				...state,
				isLoading: true,
				isError: false,
			};
		case ActionTypes.FETCH_ERROR:
			return {
				totalItems: 0,
				items: [],
				isLoading: false,
				isError: true,
				params: {
					page: 1,
					size: 10,
				},
			};
		case ActionTypes.SET_PAGE:
			return {
				...state,
				params: {
					...state.params,
					page: action.payload.page,
				},
			};
		case ActionTypes.SET_SIZE:
			return {
				...state,
				params: {
					...state.params,
					size: action.payload.size,
				},
			};
		default:
			return state;
	}
}

export function subscriptionsReducerActions(
	dispatch: React.Dispatch<any>,
): SubscriptionsReducerActions {
	function fetchSuccessAction(totalItems: number, items: ListSubscriptionsResponse['items']) {
		dispatch({
			type: ActionTypes.FETCH_SUCCESS,
			payload: {
				totalItems,
				items,
			},
		});
	}

	function fetchLoadingAction() {
		dispatch({ type: ActionTypes.FETCH_LOADING });
	}

	function fetchErrorAction() {
		dispatch({ type: ActionTypes.FETCH_ERROR });
	}

	function setPageAction(page: number) {
		dispatch({ type: ActionTypes.SET_PAGE, payload: { page } });
	}

	function setSizeAction(size: number) {
		dispatch({ type: ActionTypes.SET_SIZE, payload: { size } });
	}

	return {
		fetchSuccessAction,
		fetchLoadingAction,
		fetchErrorAction,
		setPageAction,
		setSizeAction,
	};
}
