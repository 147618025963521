import { useFormik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useToasts } from 'react-toast-notifications';
import { UpdateCompanyDTO } from '../../../../dtos/updateComapnyDTO';
import { api } from '../../../../services/api';
import Toasts from '../../../../components/bootstrap/Toasts';
import { OffCanvasBody } from '../../../../components/bootstrap/OffCanvas';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Checks from '../../../../components/bootstrap/forms/Checks';
import Popovers from '../../../../components/bootstrap/Popovers';
import Icon from '../../../../components/icon/Icon';
import { CompanyDTO } from '../../../../dtos/companyDTO';
import { registerNumberFormat } from '../../../../helpers/helpers';
import Button from '../../../../components/bootstrap/Button';

interface CompanyDataProps {
	companyData: CompanyDTO;
	response(company: CompanyDTO): void;
}

interface FormDataProps {
	name: string;
	registerNumber: string;
	slug: string;
	isBlocked: boolean;
	admin: {
		name: string;
		email: string;
		phone: string;
	};
	address: {
		zipCode: string;
		uf: string;
		city: string;
	};
}

export const CompanyData: React.FC<CompanyDataProps> = ({ companyData, response }) => {
	const { addToast } = useToasts();

	const initialValues: FormDataProps = useMemo(
		() => ({
			name: companyData.name,
			registerNumber: companyData.registerNumber,
			slug: companyData.slug,
			isBlocked: companyData.config.isBlocked,
			admin: {
				name: companyData.admin.name,
				email: companyData.admin.email,
				phone: companyData.admin.phone,
			},
			address: {
				zipCode: companyData.address.zipCode,
				uf: companyData.address.uf,
				city: companyData.address.city,
			},
		}),
		[companyData],
	);

	const handleUpdateCompany = useCallback(
		async (values: UpdateCompanyDTO) => {
			const { isBlocked, restaurantId } = values;
			try {
				if (isBlocked !== companyData.config.isBlocked) {
					await api.put('/restaurant/block', {
						restaurantId,
						block: isBlocked,
					});
				}

				addToast(
					<Toasts icon='verifiedUser' title='Sucesso' iconColor='success' isDismiss>
						Empresa editada com sucesso.
					</Toasts>,
					{
						id: 'update_company_success',
						autoDismiss: true,
						appearance: 'warning',
					},
				);

				response({
					...companyData,
					config: {
						...companyData.config,
						isBlocked,
					},
				});
			} catch (err) {
				console.log(err);
				addToast(
					<Toasts icon='bolt' title='Erro' iconColor='danger' isDismiss>
						Erro ao tentar editar empresa.
					</Toasts>,
					{
						id: 'update_company_error',
						autoDismiss: true,
						appearance: 'error',
					},
				);
			}
		},
		[companyData, addToast, response],
	);

	const formik = useFormik<FormDataProps>({
		enableReinitialize: true,
		async onSubmit(values): Promise<void | Promise<any>> {
			await handleUpdateCompany({
				restaurantId: companyData?.id ?? '',
				isBlocked: values.isBlocked,
			});
		},
		initialValues: initialValues || {
			name: '',
			registerNumber: '',
			slug: '',
			isBlocked: false,
			admin: {
				name: '',
				email: '',
				phone: '',
			},
			address: {
				zipCode: '',
				uf: '',
				city: '',
			},
		},
	});

	const linkMenu = `${process.env.REACT_APP_FRONT_BASE_URL}/${formik.values.slug}`;

	return (
		<>
			<OffCanvasBody>
				<div className='row g-4'>
					<div className='col-12'>
						<FormGroup id='name' label='Nome da empresa' isFloating>
							<Input
								disabled
								placeholder='Nome da empresa'
								onChange={formik.handleChange}
								value={formik.values.name}
							/>
						</FormGroup>
					</div>

					<div className='col-12'>
						<FormGroup id='registerNumber' label='CPF/CNPJ' isFloating>
							<Input
								disabled
								placeholder='CNPJ'
								onChange={formik.handleChange}
								value={registerNumberFormat(formik.values.registerNumber)}
							/>
						</FormGroup>
					</div>

					<div className='col-12'>
						<Card isCompact className='mb-0'>
							<CardHeader>
								<CardLabel>
									<CardTitle>Admin</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<CardLabel>
									<CardSubTitle>
										<b>Nome: </b>
										{formik.values.admin.name}
									</CardSubTitle>
								</CardLabel>

								<CardLabel
									style={{
										marginTop: 8,
									}}>
									<CardSubTitle>
										<b>E-mail: </b>
										{formik.values.admin.email}
									</CardSubTitle>
								</CardLabel>

								<CardLabel
									style={{
										marginTop: 8,
									}}>
									<CardSubTitle>
										<b>Telefone: </b>
										{formik.values.admin.phone}
									</CardSubTitle>
								</CardLabel>
							</CardBody>
						</Card>
					</div>

					<div className='col-12'>
						<Card isCompact className='mb-0'>
							<CardHeader>
								<CardLabel>
									<CardTitle>Endereço</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<CardLabel>
									<CardSubTitle>
										<b>CEP: </b>
										{formik.values.address.zipCode}
									</CardSubTitle>
								</CardLabel>

								<CardLabel
									style={{
										marginTop: 8,
									}}>
									<CardSubTitle>
										<b>Cidade: </b>
										{formik.values.address.city}
									</CardSubTitle>
								</CardLabel>

								<CardLabel
									style={{
										marginTop: 8,
									}}>
									<CardSubTitle>
										<b>Estado: </b>
										{formik.values.address.uf}
									</CardSubTitle>
								</CardLabel>
							</CardBody>
						</Card>
					</div>

					<div className='col-12'>
						<Card isCompact className='mb-0'>
							<CardHeader>
								<CardLabel>
									<CardTitle>Link Cardápio</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<CardLabel>
									<CardSubTitle>
										<a
											href={linkMenu}
											target='_blank'
											rel='noopener noreferrer'>
											{linkMenu}
										</a>
									</CardSubTitle>
								</CardLabel>
							</CardBody>
						</Card>
					</div>

					<div className='col-12'>
						<Card isCompact className='mb-0'>
							<CardHeader>
								<CardLabel>
									<CardTitle>Bloquear empresa</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<FormGroup>
									<Checks
										id='isBlocked'
										type='switch'
										label={
											<>
												Sobre esse bloqueio
												<Popovers
													trigger='hover'
													desc='Check this checkbox if you want your customer to receive an email about the scheduled appointment'>
													<Icon
														icon='Help'
														size='lg'
														className='ms-1 cursor-help'
													/>
												</Popovers>
											</>
										}
										onChange={formik.handleChange}
										checked={formik.values.isBlocked}
									/>
								</FormGroup>
							</CardBody>
						</Card>
					</div>
				</div>
			</OffCanvasBody>

			<Button color='info' className='m-3' size='lg' onClick={formik.handleSubmit}>
				Save
			</Button>
		</>
	);
};
