import showNotification from '../../../../components/extras/showNotification';
import { FullSubscription } from '../../../../dtos/Subscription';
import { getDistance, getMonths, getWeeks } from '../../../../utils/date';

export const intervalTypeParsed = {
	WEEKS: 'Semanas',
	MONTHS: 'Meses',
};

export const subscriberTypeParsed = {
	STORE: 'Loja',
	CLIENT: 'Cliente',
	MOTOBOY: 'Motoboy',
	WAITER: 'Garçom',
	AFFILIATE: 'Afiliado',
};

export const financialNotifications = {
	canceledSubscription: () => {
		showNotification('Ops!', 'A assinatura está cancelada', 'info');
	},
	onlyStoreInvoices: () => {
		showNotification('Ops!', 'Criação de faturas disponível apenas para "Loja"', 'info');
	},
	sendInvoiceOnlyStore: () => {
		showNotification('Ops!', 'Envio de faturas disponível apenas para "Loja"', 'info');
	},
	genericError: () => {
		showNotification('Falha!', 'Ocorreu algum erro!', 'danger');
	},
	canceledInvoice: () => {
		showNotification('Ops!', 'A fatura está cancelada', 'info');
	},
	dueInvoice: () => {
		showNotification('Ops!', 'A fatura está vencida', 'info');
	},
	paidInvoice: () => {
		showNotification('Ops!', 'A fatura está paga', 'info');
	},
	sendedInvoice: () => {
		showNotification('Fatura enviada!', 'A Fatura foi enviada com sucesso', 'success');
	},
	cancelInvoice: () => {
		showNotification('Fatura cancelada!', 'A Fatura foi cancelada com sucesso', 'success');
	},
	payInvoice: () => {
		showNotification('Fatura paga!', 'A Fatura foi paga com sucesso', 'success');
	},
	notDueInvoice: () => {
		showNotification('Ops!', 'A fatura não está vencida', 'info');
	},
	pastDueDateInvoice: () => {
		showNotification('Ops!', 'A data de vencimento não pode estar no passado', 'info');
	},
	reissuedInvoice: () => {
		showNotification('Fatura reemitida!', 'A Fatura foi reemitida com sucesso', 'success');
	},
};

export const invoiceUtils = {
	getUseOfPlan: ({ plan, createdAt, canceledAt }: FullSubscription) => {
		const getUse = {
			WEEKS: getWeeks,
			MONTHS: getMonths,
		};
		return getUse[plan.intervalType](createdAt, canceledAt) / plan.interval;
	},
	getDistanceUseOfPlan: ({ createdAt, canceledAt }: FullSubscription) => {
		return getDistance(createdAt, canceledAt);
	},
};
