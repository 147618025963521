import React, { useCallback, useEffect, useReducer, useState } from 'react';
import Swal from 'sweetalert2';
import Page from '../../../layout/Page/Page';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../components/bootstrap/Card';
import PaginationButtons from '../../../components/PaginationButtons';

import { financialService } from '../../../services/financial';
import { LoadingPage } from '../../../components/pages/LoadingPage';
import { ErrorPage } from '../../../components/pages/ErrorPage';
import Badge from '../../../components/bootstrap/Badge';
import Button from '../../../components/bootstrap/Button';
import { RelativeTime } from '../../../components/RelativeTime';
import Modal from '../../../components/bootstrap/Modal';
import {
	subscriptionsReducer,
	subscriptionsReducerActions,
} from '../../../reducers/subscriptionsReducer';
import { subscriberTypeParsed } from './components/utils';
import { NewStoreSubscriptionModal } from './components/NewStoreSubscriptionModal';
import Icon from '../../../components/icon/Icon';
import showNotification from '../../../components/extras/showNotification';
import { defaultAlertOptions } from '../../../utils/constants';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { financialPageMenu } from '../../../menu';
import { InvoicesModal } from './components/InvoicesModal';
import { FullSubscription } from '../../../dtos/Subscription';

const SubscriptionsPage: React.FC = () => {
	const [modal, setModal] = useState(false);
	const [invoicesModal, setInvoicesModal] = useState(false);
	const [subscription, setSubscription] = useState<FullSubscription>({} as FullSubscription);

	const [state, dispatch] = useReducer(subscriptionsReducer, {
		totalItems: 0,
		items: [],
		isLoading: false,
		isError: false,
		params: {
			page: 1,
			size: 10,
		},
	});

	const { isError, isLoading, params } = state;

	const actions = subscriptionsReducerActions(dispatch);

	const fetchData = useCallback(async () => {
		actions.fetchLoadingAction();

		try {
			const { totalItems, items } = await financialService.subscriptions.list({
				params: {
					page: params.page - 1,
					size: params.size,
				},
			});

			actions.fetchSuccessAction(totalItems, items);
		} catch {
			actions.fetchErrorAction();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params]);

	useEffect(() => {
		if (!isError) {
			fetchData();
		}
	}, [fetchData, isError]);

	if (isLoading) return <LoadingPage />;

	if (isError) return <ErrorPage />;

	const handleCancel = async (subscriptionId: string) => {
		Swal.fire({
			title: `Cancelar Assinatura`,
			text: 'Tem certeza que deseja Cancelar essa Assinatura?',
			...defaultAlertOptions,
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					await financialService.subscriptions.cancel(subscriptionId);
					fetchData();
				} catch {
					showNotification('Falha!', 'Ocorreu algum erro!', 'danger');
				}
			}
		});
	};

	const handleReactivate = async (subscriptionId: string) => {
		Swal.fire({
			title: `Reativar Assinatura`,
			text: 'Tem certeza que deseja Reativar essa Assinatura?',
			...defaultAlertOptions,
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					await financialService.subscriptions.reactivate(subscriptionId);
					fetchData();
				} catch {
					showNotification('Falha!', 'Ocorreu algum erro!', 'danger');
				}
			}
		});
	};

	return (
		<PageWrapper title={financialPageMenu.financial.subMenu.subscriptions.text}>
			<Page container='fluid'>
				<div className='row'>
					<div className='col-12'>
						<Card>
							<CardHeader borderSize={1}>
								<CardLabel icon='Option' iconColor='info'>
									<CardTitle>Assinaturas</CardTitle>
									<CardSubTitle>
										<Badge className='fs-6' isLight color='info'>
											{state.totalItems} Assinaturas
										</Badge>
									</CardSubTitle>
								</CardLabel>

								<CardActions>
									<Button
										color='primary'
										icon='Add'
										onClick={() => {
											setModal(true);
										}}>
										Criar Assinatura
									</Button>
								</CardActions>
							</CardHeader>

							<CardBody className='table-responsive'>
								<table className='table table-modern'>
									<thead>
										<tr>
											<th>Assinante</th>
											<th>Tipo do Assinante</th>
											<th>Plano</th>
											<th>Data da Assinatura</th>
											<th> </th>
											<th> </th>
											<th> </th>
										</tr>
									</thead>
									<tbody>
										{state.items.map((item) => (
											<tr key={item.id}>
												<td>
													<Icon
														icon='Circle'
														color={
															item.canceledAt ? 'danger' : 'success'
														}
														className='m-2'
													/>
													{item.subscriber.name}
												</td>
												<td>{subscriberTypeParsed[item.subscriberType]}</td>
												<td>{item.plan.name}</td>
												<td>
													<RelativeTime
														datetime={item.createdAt.toString()}
													/>
												</td>
												<td>
													{item.indicatorId && (
														<Badge className='fs-6' color='info'>
															Por Indicação
														</Badge>
													)}
												</td>

												<td>
													{item.canceledAt ? (
														<Button
															size='sm'
															color='success'
															icon='SettingsBackupRestore'
															onClick={() =>
																handleReactivate(item.id)
															}>
															Reativar
														</Button>
													) : (
														<Button
															size='sm'
															color='danger'
															icon='Block'
															onClick={() => handleCancel(item.id)}>
															Cancelar
														</Button>
													)}
												</td>

												<td>
													<Button
														size='sm'
														color='primary'
														onClick={() => {
															setSubscription(item);
															setInvoicesModal(true);
														}}>
														Ver Faturas
													</Button>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</CardBody>

							<PaginationButtons
								data={[...new Array(state.totalItems)]}
								label='Assinaturas'
								currentPage={params.page}
								perPage={params.size}
								setCurrentPage={actions.setPageAction}
								setPerPage={actions.setSizeAction}
							/>
						</Card>
					</div>
				</div>

				<Modal
					isOpen={modal}
					setIsOpen={setModal}
					size='lg'
					titleId='new-store-subscription'
					isCentered
					isStaticBackdrop>
					<NewStoreSubscriptionModal setModalStatus={setModal} fetchData={fetchData} />
				</Modal>

				<Modal
					isOpen={invoicesModal}
					setIsOpen={setInvoicesModal}
					size='xl'
					titleId='invoices'
					isCentered
					isStaticBackdrop>
					<InvoicesModal setModalStatus={setInvoicesModal} subscription={subscription} />
				</Modal>
			</Page>
		</PageWrapper>
	);
};

export default SubscriptionsPage;
