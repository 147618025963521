import React from 'react';
import * as y from 'yup';
import {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../../components/bootstrap/Modal';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Button from '../../../../components/bootstrap/Button';
import Input from '../../../../components/bootstrap/forms/Input';
import { useFormik } from 'formik';
import Spinner from '../../../../components/bootstrap/Spinner';
import { financialService } from '../../../../services/financial';
import showNotification from '../../../../components/extras/showNotification';

interface Props {
	setModalStatus: React.Dispatch<React.SetStateAction<boolean>>;
	fetchData: () => Promise<void>;
}

const schema = y.object({
	planName: y.string().trim().required(),
	storeId: y.string().trim().required(),
	indicatorId: y.string().trim().optional(),
});

type SchemaType = y.InferType<typeof schema>;

export const NewStoreSubscriptionModal = ({ setModalStatus, fetchData }: Props) => {
	const formik = useFormik<SchemaType>({
		initialValues: {
			planName: '',
			indicatorId: '',
			storeId: '',
		},
		validationSchema: schema,
		onSubmit: async (data) => {
			try {
				await financialService.subscriptions.createStore(data);
				showNotification(
					'Assinatura criada!',
					'A Assinatura foi criado com sucesso!',
					'success',
				);
				setModalStatus(false);
				fetchData();
			} catch (error: any) {
				if (error.response.data.message.includes('Plan not found')) {
					formik.setFieldError('planName', 'Plano não encontrado');
					return;
				}

				if (error.response.data.message.includes('Store not found')) {
					formik.setFieldError('storeId', 'Loja não encontrada');
					return;
				}

				if (
					error.response.data.message.includes(
						'The store already has a subscription to the plan',
					)
				) {
					formik.setFieldError(
						'storeId',
						'Essa loja já possui uma assinatura nesse plano',
					);
					return;
				}

				showNotification('Falha!', 'Ocorreu algum erro!', 'danger');
			}
		},
	});

	return (
		<>
			<ModalHeader setIsOpen={setModalStatus}>
				<ModalTitle id='new-store-subscription'>Criar Assinatura (De uma Loja)</ModalTitle>
			</ModalHeader>

			<ModalBody className='d-flex row g-4'>
				<FormGroup id='planName' label='Nome do Plano'>
					<Input
						size='lg'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.planName}
						isValid={formik.isValid}
						isTouched={formik.touched?.planName}
						invalidFeedback={formik.errors?.planName}
						validFeedback='Parece bom!'
					/>
				</FormGroup>

				<FormGroup id='storeId' label='ID da Loja'>
					<Input
						size='lg'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.storeId}
						isValid={formik.isValid}
						isTouched={formik.touched?.storeId}
						invalidFeedback={formik.errors?.storeId}
						validFeedback='Parece bom!'
					/>
				</FormGroup>

				<FormGroup id='indicatorId' label='ID no Indicador'>
					<Input
						size='lg'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.indicatorId}
						isValid={formik.isValid}
						isTouched={formik.touched?.indicatorId}
						invalidFeedback={formik.errors?.indicatorId}
						validFeedback='Parece bom!'
					/>
				</FormGroup>
			</ModalBody>

			<ModalFooter>
				<Button icon='Close' color='danger' isLink onClick={() => setModalStatus(false)}>
					Cancelar
				</Button>
				<Button
					icon='DoneOutline'
					color='success'
					isLight
					isDisable={!formik.isValid || formik.isSubmitting}
					onClick={formik.handleSubmit}>
					{formik.isSubmitting && <Spinner isSmall inButton />}
					{formik.isSubmitting ? 'Criando Assinatura' : 'Criar Assinatura'}
				</Button>
			</ModalFooter>
		</>
	);
};
