import React from 'react';
import Page from '../../layout/Page/Page';
import Spinner from '../bootstrap/Spinner';

export const LoadingPage = () => {
	return (
		<Page container='fluid'>
			<div className='row justify-content-center'>
				<Spinner color='primary' size={32} />
			</div>
		</Page>
	);
};
