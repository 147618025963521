import React from 'react';
import Page from '../../layout/Page/Page';
import Alert from '../bootstrap/Alert';

interface Props {
	errorMessage?: string;
}

export const ErrorPage = ({ errorMessage }: Props) => {
	return (
		<Page container='fluid'>
			<Alert color='danger' icon='Error' isLight>
				<span>{errorMessage || 'Desculpe, não foi possível carregar os dados.'}</span>
			</Alert>
		</Page>
	);
};
