import React from 'react';
import { RouteProps } from 'react-router-dom';
import { publicPagesMenu } from '../menu';
import DefaultHeader from '../pages/_layout/_headers/DefaultHeader';

const headers: RouteProps[] = [
	{ path: publicPagesMenu.login.path, element: null },
	{ path: publicPagesMenu.signUp.path, element: null },
	{ path: publicPagesMenu.page404.path, element: null },
	{
		path: `*`,
		element: <DefaultHeader />,
	},
];

export default headers;
