import React, { useCallback, useEffect, useReducer, useState } from 'react';
import Page from '../../../layout/Page/Page';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../components/bootstrap/Card';
import PaginationButtons from '../../../components/PaginationButtons';

import { financialService } from '../../../services/financial';
import { plansReducer, plansReducerActions } from '../../../reducers/plansReducer';
import { LoadingPage } from '../../../components/pages/LoadingPage';
import { ErrorPage } from '../../../components/pages/ErrorPage';
import Badge from '../../../components/bootstrap/Badge';
import Button from '../../../components/bootstrap/Button';
import { RelativeTime } from '../../../components/RelativeTime';
import { Currency } from '../../../components/Currency';
import { NewPlanModal } from './components/NewPlanModal';
import Modal from '../../../components/bootstrap/Modal';
import { intervalTypeParsed } from './components/utils';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { financialPageMenu } from '../../../menu';

const PlansPage: React.FC = () => {
	const [modal, setModal] = useState(false);

	const [state, dispatch] = useReducer(plansReducer, {
		totalItems: 0,
		items: [],
		isLoading: false,
		isError: false,
		params: {
			page: 1,
			size: 10,
		},
	});

	const { isError, isLoading, params } = state;

	const actions = plansReducerActions(dispatch);

	const fetchData = useCallback(async () => {
		actions.fetchLoadingAction();

		try {
			const { totalItems, items } = await financialService.plans.list({
				params: {
					page: params.page - 1,
					size: params.size,
				},
			});

			actions.fetchSuccessAction(totalItems, items);
		} catch {
			actions.fetchErrorAction();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params]);

	useEffect(() => {
		if (!isError) {
			fetchData();
		}
	}, [fetchData, isError]);

	if (isLoading) return <LoadingPage />;

	if (isError) return <ErrorPage />;

	return (
		<PageWrapper title={financialPageMenu.financial.subMenu.plans.text}>
			<Page container='fluid'>
				<div className='row'>
					<div className='col-12'>
						<Card>
							<CardHeader borderSize={1}>
								<CardLabel icon='Option' iconColor='info'>
									<CardTitle>Planos</CardTitle>
									<CardSubTitle>
										<Badge className='fs-6' isLight color='info'>
											{state.totalItems} Planos
										</Badge>
									</CardSubTitle>
								</CardLabel>

								<CardActions>
									<Button
										color='primary'
										icon='Add'
										onClick={() => {
											setModal(true);
										}}>
										Criar Plano
									</Button>
								</CardActions>
							</CardHeader>

							<CardBody className='table-responsive'>
								<table className='table table-modern'>
									<thead>
										<tr>
											<th>Nome</th>
											<th>Intervalo</th>
											<th>Tipo do Intervalo</th>
											<th>Valor</th>
											<th>Data de Criação</th>
										</tr>
									</thead>
									<tbody>
										{state.items.map((item) => (
											<tr key={item.id}>
												<td>{item.name}</td>
												<td>{item.interval}</td>
												<td>{intervalTypeParsed[item.intervalType]}</td>
												<td>
													<Currency value={item.value} />
												</td>
												<td>
													<RelativeTime
														datetime={item.createdAt.toString()}
													/>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</CardBody>

							<PaginationButtons
								data={[...new Array(state.totalItems)]}
								label='Planos'
								currentPage={params.page}
								perPage={params.size}
								setCurrentPage={actions.setPageAction}
								setPerPage={actions.setSizeAction}
							/>
						</Card>
					</div>
				</div>

				<Modal
					isOpen={modal}
					setIsOpen={setModal}
					size='lg'
					titleId='new-plan'
					isCentered
					isStaticBackdrop>
					<NewPlanModal setModalStatus={setModal} fetchData={fetchData} />
				</Modal>
			</Page>
		</PageWrapper>
	);
};

export default PlansPage;
