import { FullSubscription } from '../../dtos/Subscription';
import { api } from '../api';

export type ListSubscriptionsRequest = {
	params: {
		page: number;
		size: number;
	};
};

export type ListSubscriptionsResponse = {
	totalItems: number;
	items: Array<FullSubscription>;
};

export const listSubscriptions = async ({
	params,
}: ListSubscriptionsRequest): Promise<ListSubscriptionsResponse> =>
	(await api.get(`/financial/subscriptions`, { params })).data;
