import React from 'react';
import Spinner from '../bootstrap/Spinner';
import { ModalBody, ModalHeader, ModalTitle } from '../bootstrap/Modal';

type Props = {
	modalTitle: string;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const LoadingModal = ({ modalTitle, setIsOpen }: Props) => {
	return (
		<>
			<ModalHeader setIsOpen={setIsOpen}>
				<ModalTitle id={modalTitle}>{modalTitle}</ModalTitle>
			</ModalHeader>

			<ModalBody>
				<div className='row justify-content-center'>
					<Spinner color='primary' size={32} />
				</div>
			</ModalBody>
		</>
	);
};
