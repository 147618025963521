import { cancelInvoice } from './cancelInvoice';
import { cancelSubscription } from './cancelSubscription';
import { createInvoice } from './createInvoice';
import { createPlan } from './createPlan';
import { createStoreSubscription } from './createStoreSubscription';
import { listInvoicesBySubscription } from './listInvoicesBySubscription';
import { listPlans } from './listPlans';
import { listSubscriptions } from './listSubscriptions';
import { payInvoiceExternally } from './payInvoiceExternally';
import { reactivateSubscription } from './reactivateSubscription';
import { reissueExpiredInvoice } from './reissueExpiredInvoice';
import { sendInvoiceByEmail } from './sendInvoiceByEmail';
import { sendInvoiceByWhatsapp } from './sendInvoiceByWhatsapp';

export const financialService = {
	plans: {
		list: listPlans,
		create: createPlan,
	},
	subscriptions: {
		list: listSubscriptions,
		createStore: createStoreSubscription,
		cancel: cancelSubscription,
		reactivate: reactivateSubscription,
	},
	invoices: {
		create: createInvoice,
		cancel: cancelInvoice,
		payExternally: payInvoiceExternally,
		listBySubscription: listInvoicesBySubscription,
		sendByWhatsapp: sendInvoiceByWhatsapp,
		sendByEmail: sendInvoiceByEmail,
		reissueExpired: reissueExpiredInvoice,
	},
};
