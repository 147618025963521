import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { api } from '../services/api';

export interface IUserAuth {
	accessToken: string;
	refreshToken: string;
}

export interface IUserData {
	id: string;
	name: string;
	email: string;
	image: string;
	role: string;
	createdAt: Date;
	updatedAt: Date;
	metadata: any[];
	restaurants: any[];
}

export interface IAuthContextProps {
	user: IUserAuth | undefined;
	setUser(userAuth: IUserAuth | undefined): void;
	userData: Partial<IUserData>;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [user, setUser] = useState<IUserAuth | undefined>(() => {
		const localUserAuth = localStorage.getItem('@nossaentraga_auth');

		if (localUserAuth) {
			const { accessToken, refreshToken } = JSON.parse(localUserAuth) as IUserAuth;

			api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

			return {
				accessToken,
				refreshToken,
			};
		}

		return undefined;
	});

	const [userData, setUserData] = useState<Partial<IUserData>>({});

	const logout = () => {
		setUserData({});
		setUser(undefined);
		localStorage.removeItem('@nossaentraga_auth');
	};

	useEffect(() => {
		(async () => {
			if (user?.accessToken) {
				api.defaults.headers.common.Authorization = `Bearer ${user.accessToken}`;

				try {
					const { data } = await api.get<IUserData>('/admin/auth');

					if (data.role === 'SUPER_ADMIN') {
						setUserData(data);
						localStorage.setItem('@nossaentraga_auth', JSON.stringify(user));
					} else {
						logout();
					}
				} catch (err: any) {
					if (err.response.data.message === 'Invalid token!') {
						logout();
					}
					console.log(err);
				}
			}
		})();
	}, [user]);

	const value = useMemo(
		() => ({
			user,
			setUser,
			userData,
		}),
		[user, userData],
	);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
