import React from 'react';
import useDarkMode from '../../../../hooks/useDarkMode';
import { TColor } from '../../../../type/color-type';

interface CardDataProps {
	label: string;
	value: (string | number)[];
	color?: TColor;
}

export const CardData = ({ label, value, color = 'info' }: CardDataProps) => {
	const { darkModeStatus } = useDarkMode();

	return (
		<div
			className={`d-flex align-items-baseline bg-l${
				darkModeStatus ? 'o25' : '10'
			}-${color} rounded-2 p-3`}>
			<div className='flex-grow-1'>
				<div className='fw-bold fs-5 mb-0'>{label}</div>
				{value?.map((v) => (
					<div key={v} className='truncate-line-1'>
						{v}
					</div>
				))}
			</div>
		</div>
	);
};
