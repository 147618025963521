export const currencyToNumber = (number: string): string => {
	const currentValue = number
		.replace(/[^\d]+/g, '')
		.replace(/^[0]+/, '')
		.padStart(3, '0');

	const parsedValue = `${currentValue.slice(0, -2)}.${currentValue.slice(-2)}`;

	return parsedValue;
};

export const numberToCurrency = (num: number | string): string => {
	const currency = new Intl.NumberFormat('pt-BR', {
		style: 'currency',
		currency: 'BRL',
	}).format(Number(num));

	return currency;
};
