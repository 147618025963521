import { Plan } from '../../dtos/Plan';
import { api } from '../api';

export type CreatePlanRequest = {
	name: string;
	interval: number;
	intervalType: Plan['intervalType'];
	value: number;
};

export const createPlan = async (data: CreatePlanRequest): Promise<Plan> =>
	(await api.post(`/financial/plans`, data)).data;
