import { Invoice } from '../../dtos/Invoice';
import { api } from '../api';

export type CreateInvoiceRequest = {
	subscriptionId: string;
	dueDate: Date;
};

export const createInvoice = async (data: CreateInvoiceRequest): Promise<Invoice> =>
	(await api.post(`/financial/invoices`, data)).data;
