import { Subscription } from '../../dtos/Subscription';
import { api } from '../api';

export type CreateStoreSubscriptionRequest = {
	planName: string;
	storeId: string;
	indicatorId?: string;
};

export const createStoreSubscription = async (
	data: CreateStoreSubscriptionRequest,
): Promise<Subscription> => (await api.post(`/financial/subscriptions/store`, data)).data;
