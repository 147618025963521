import React, { lazy } from 'react';
import { dashboardPagesMenu, publicPagesMenu, companiesPageMenu, financialPageMenu } from '../menu';
import Login from '../pages/presentation/auth/Login';
import CompaniesPage from '../pages/presentation/companies/CompaniesPage';
import PlansPage from '../pages/presentation/financial/PlansPage';
import SubscriptionsPage from '../pages/presentation/financial/SubscriptionsPage';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		path: publicPagesMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: publicPagesMenu.login.path,
		element: <Login />,
	},
	{
		path: publicPagesMenu.signUp.path,
		element: <Login isSignUp />,
	},

	/** ************************************************** */

	/**
	 * Page Companies
	 */
	{
		path: companiesPageMenu.companies.path,
		element: <CompaniesPage />,
	},
	{
		path: financialPageMenu.financial.subMenu.plans.path,
		element: <PlansPage />,
	},
	{
		path: financialPageMenu.financial.subMenu.subscriptions.path,
		element: <SubscriptionsPage />,
	},
];
const contents = [...presentation];

export default contents;
